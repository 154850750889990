import { createApp } from 'vue'
import App from './App.vue'
import { createI18n } from 'vue-i18n'
import router from './router'

// 引入Tailwind CSS
import './assets/css/tailwind.css'

// 引入Font Awesome
import '@fortawesome/fontawesome-free/css/all.min.css'

// 多语言配置
import zhCN from './locales/zh-CN.json'
import enUS from './locales/en-US.json'
import thTH from './locales/th-TH.json'
import msMY from './locales/ms-MY.json'

const i18n = createI18n({
  legacy: false,
  locale: 'zh-CN',
  fallbackLocale: 'zh-CN',
  messages: {
    'zh-CN': zhCN,
    'en-US': enUS,
    'th-TH': thTH,
    'ms-MY': msMY
  }
})

createApp(App)
  .use(router)
  .use(i18n)
  .mount('#app') 