<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#app {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

/* 移动端点击时去除高亮效果 */
* {
  -webkit-tap-highlight-color: transparent;
}

/* 修复Tailwind和FontAwesome的一些兼容性问题 */
.far, .fas, .fab {
  display: inline-block;
  vertical-align: middle;
}
</style> 